<template>
  <div class="newest-page">
    <div class="title">{{ $t('common.tab2') }}</div>
    <div class="goods-list flex-wrap">
      <div class="list" v-for="(item, i) in goodsList" :key="i" @click="toDetail(item)">
        <div class="img flex-center">
          <img :src="item.Product.MainImage" alt="">
        </div>
        <div class="label">
          {{item.Product.ProductTranslation[0].Name}}   
        </div>
        <div class="price">
          ${{item.Price}}
        </div>
        <div class="info-box">
          <div class="info-line">
            <span>{{ $t('qi-hao-2') }}</span>
            <span>{{item.Period}}</span>  
          </div>  

          <div class="waiting-box mt-5" v-if="item.Status == 1">
            <div class="flex-center-start">
              <img :src="clockUrl" alt="">
              {{getStatusName(item.StartTime, item.EndTime)}}
            </div>
            <div class="big" :key="`${item.ID}-${key}`">{{getTime(item.StartTime, item.EndTime)}}</div>
          </div>
          <div class="" v-else>
             <div class="info-line">
              <span>{{ $t('can-yu-ren-shu') }}</span>
              <span>{{item.JoinCount}}</span>  
            </div>  
             <div class="info-line">
              <span>{{ $t('jie-xiao-shi-jian') }}</span>
              <span>{{item.UpdatedAt}}</span>  
            </div>  
          </div>
        </div>   
      </div>
    </div>
  </div>
</template>
<script>
import { periodsList} from '@/api/index'
import { getDownTime, getStartTime } from '@/utils/common';
export default {
  data() {
    return {
      pageStatus: '',
      key: 0,
      timer: '',
      goodsList: [],
      newestUrl: require('../../assets/imgs/newest-goods.jpg'),
      clockUrl: require('../../assets/imgs/icon-clock.png'),
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    toDetail(data) {
       sessionStorage.setItem('prizeDetailData', JSON.stringify(data))
      this.$router.push({
        name: 'prizeDetail',
        query: {
          id: data.ID
        }
      })
    },
    init() {
      let form = new FormData()
      form.append('OrderBy', 'at_once')
      periodsList(form).then(res => {
        this.goodsList = res.data.Items
        this.timer = setInterval(() => {
          this.key += 1
        }, 1000);
      })
    },
    getStatusName(start, end) {
      let now = new Date().getTime()
      let endTime = new Date(end).getTime()
      let startTime = new Date(start).getTime()
      if (now >= endTime) {
        return this.$t('yi-jie-shu')
      } else if (now < endTime && now >= startTime) {
        return this.$t('jin-hang-zhong')
      } else {
        return this.$t('wei-kai-shi')
      }
    },
    getTime(start, end) {
      let now = new Date().getTime()
      let endTime = new Date(end).getTime()
      let startTime = new Date(start).getTime()
      if (now >= endTime) {
        return this.$t('jiang-pin-jie-xiao-zhong')
      } else if (now < endTime && now >= startTime) {
        return getDownTime(start, end)
      } else {
        return getStartTime(start)
      }
    }
  }
}
</script>