<template>
  <div>
  正在登录...
  </div>
</template>
<script>
  import { Button, Popup, Icon, Divider, Checkbox  } from 'vant'
  import { userLogin } from '@/api/login'
  import {getToken, setToken, setUser, setUserId} from '@/utils/auth'
  import topBar from '@/components/topBar'
  export default {
    components: {
      Button,
      Popup,
      Icon,
      Divider,
      Checkbox,
      topBar
    },
    data() {
      return {
        form: {
          phone: '',
          email: '',
          password: '',
          Fingerprint: ''
        },
        key: "",
        userDataBak: {}
      }
    },
    computed: {
      countryListBak() {
        return this.$store.state.countryList
      },
      logo() {
        return this.$store.state.logo
      },
      appName(){
        return this.$store.state.appName
      }
    },
    mounted() {
      // 从url中获取参数key
      this.key = this.$route.query.key
      if (this.key) {
        let form = new FormData()
        form.append('Key', this.key)
        userLogin(form).then(res => {
          if (res.code == 0) {
            setToken(res.data.token)
            setUserId(res.data.id)
            this.$router.push({
              name: 'index'
            })
          } else {
            if (getToken()) {
              this.$router.push({
                name: 'index'
              })
              return
            }
            alert(res.msg)
          }
        })
      } else {
        alert("参数错误")
      }
    },
    methods: {
      back() {
        this.$router.push({
          name: 'index'
        })
      },
      toPath(path) {
        if (path) {
          this.$router.push({
            name: path
          })
        }
      },
      changeTab(index) {
        this.tabIndex = index
      },
      submit() {

      },
      cancel() {
        this.showPopup = false
      },
      changePre() {
        this.showPopup = true
      },
      chooseCountry(data) {
        this.phonePre = data.key
        this.showPopup = false
      },
      getCountryName(name) {
        if (!name) {
          return ''
        }
        let newName = ''
        newName = name.substring(0, name.indexOf('(') - 1)
        return newName
      },
      searchCountry() {
        if (this.keyword) {
          this.countryList = this.countryListBak.map(v => {
            let children = v.children.filter(val => {
              return val.title.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
            })
            if (children.length > 0) {
              return {
                children: children
              }
            } else {
              return {
                children: []
              }
            }
          })
        } else {
          this.countryList = [].concat(this.countryListBak)
        }
      },
      toFastPage(key) {
        let dom = document.getElementById(key.id)
        let parentDom = document.querySelector('.country-list')
        if (dom && parentDom) {
          let domTop = dom.offsetTop
          parentDom.scrollTo({
            top: domTop - 124
          })
        }
      },
    },
  }
</script>