import fetch from "../utils/fetch";
import qs from "qs";


// 轮播图
export function getBannerList(data) {
  return fetch({
    url: '/api/user/banner',
    method: 'post',
    data: data
  });
}
// 积分礼品
export function giftList(data) {
  return fetch({
    url: '/api/mobile/BonusGift-lists',
    method: 'post',
    data: data
  });
}
// 签到
export function userSign(data) {
  return fetch({
    url: '/api/mobile/check-in',
    method: 'post',
    data: data
  });
}
// 分类列表
export function getCategoryList(data) {
  return fetch({
    url: '/api/user/product-category-list',
    method: 'post',
    data: data
  });
}
// 商家产品列表
export function shopGoods(data) {
  return fetch({
    url: '/api/user/business-product-list',
    method: 'post',
    data: data
  });
}
// 内容列表
export function articleList(data) {
  return fetch({
    url: '/api/user/article-lists',
    method: 'post',
    data: data
  });
}
// 期号列表
export function periodsList(data) {
  return fetch({
    url: '/api/user/periods-lists',
    method: 'post',
    data: data
  });
}
// 参与记录
export function participationList(data) {
  return fetch({
    url: '/api/user/participation-list',
    method: 'post',
    data: data
  });
}
// 立即参与
export function participationAdd(data) {
  return fetch({
    url: '/api/user/participation-add',
    method: 'post',
    data: data
  });
}
// 抽奖列表
export function getRaffleList(data) {
  return fetch({
    url: '/api/user/PrizeDraw-list',
    method: 'post',
    data: data
  });
}
// 抽奖
export function doRaffle(data) {
  return fetch({
    url: '/api/user/PrizeDrawRecord-receive',
    method: 'post',
    data: data
  });
}
// 下一期
export function nextPeriod(data) {
  return fetch({
    url: '/api/user/next-period',
    method: 'post',
    data: data
  });
}
// 中奖提示
export function winningTips(data) {
  return fetch({
    url: '/api/user/winning-tips',
    method: 'post',
    data: data
  });
}
// 中奖已读
export function winningRead(data) {
  return fetch({
    url: '/api/user/winning-tips-read',
    method: 'post',
    data: data
  });
}
// 地址提交
export function winningAddressAdd(data) {
  return fetch({
    url: '/api/user/winning-address-add',
    method: 'post',
    data: data
  });
}
// 首页消息
export function getNoticeList(data) {
  return fetch({
    url: '/api/user/latest-winning',
    method: 'post',
    data: data
  });
}
