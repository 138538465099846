<template>
  <div class="prize-page">
    <top-bar :title="$t('jiang-pin-xiang-qing')" :isWhite="true"  @back="back"></top-bar>

    <div class="banner">
      <Swipe :autoplay="3000"  indicator-color="#d93a55">
        <SwipeItem v-for="(item, i) in bannerList" :key="i">
          <div class="img">
            <img :src="item" alt="">
          </div>
        </SwipeItem>
      </Swipe>
    </div>

    <div class="info">
      <div class="status flex-center-start">
        <div class="tag green" v-if="pageStatus == 1">{{detailData.Status == 2 ? $t('yi-jie-xiao') : $t('dai-jie-xiao')}}</div>
        <div class="tag yellow" v-if="pageStatus == 2">{{ $t('wei-kai-shi') }}</div>
        <div class="tag red" v-if="pageStatus == 3">{{ $t('jin-hang-zhong') }}</div>
        <div class="name"> {{detailData.Product ? detailData.Product.ProductTranslation[0].Name : ''}}   </div>
      </div>

      <div class="price">${{detailData.Price}}</div>

      <div class="user-detail flex-start" v-if="detailData.Status == 2 && pageStatus == 1">
        <div class="header">
          <img :src="headerUrl" alt="">
        </div>
        <div class="flex-1">
          <div class="info-line">
            <span class="label">{{ $t('yong-hu-id') }}</span>
            <span class="">
              <span>{{winId}}</span>
              {{ $t('wei-yi-bu-bian-biao-shi') }} </span>
          </div>
          <div class="info-line">
            <span class="label">{{ $t('qi-hao-0') }}</span>
            <span class="">{{detailData.Period}}</span>
          </div>
          <div class="info-line">
            <span class="label">{{ $t('ben-qi-can-yu') }}</span>
            <span class="">
              <span class="red">{{detailData.JoinCount}}</span>{{ $t('ren-ci') }}
            </span>
          </div>
          <div class="info-line">
            <span class="label">{{ $t('jie-xiao-shi-jian') }}</span>
            <span class="">{{ formatTimeByDayjs(detailData.UpdatedAt)}}</span>
          </div>
        </div>
      </div>

      <div class="prize-detail flex-center-between" v-if="pageStatus == 2">
        <div>
          <div>{{ $t('qi-hao-1') }}<span>{{detailData.Period}}</span></div>
          <div>{{ $t('kai-shi-dao-ji-shi') }} <span class="big" :key="key">{{getStartTime(detailData.StartTime)}}</span></div>
        </div>
        <div>
          <div class="link">{{ $t('ji-suan-xiang-qing') }}</div>
        </div>
      </div>

      <div class="join-percent" v-if="pageStatus == 3">
        <div class="number">{{ $t('qi-hao-2') }}<span>{{detailData.Period}}</span></div>
        <div class="percent-box">
          <div class="percent" :style="{width: `${detailData.JoinCount ? detailData.JoinCount / detailData.LimitCount * 100 : 0}%`}"></div>
        </div>
        <div class="flex-center-between">
          <div>{{ $t('zong-xu') }}<span>{{detailData.LimitCount || 0}}</span>{{ $t('ren-ci-0') }}</div>
          <div>{{ $t('sheng-yu') }}<span class="blue">{{detailData.LimitCount ? detailData.LimitCount - detailData.JoinCount : 0}}</span>{{ $t('ren-ci-1') }}</div>
        </div>
        <div class="flex mt-10">
          <div class="down-label">{{ $t('dao-ji-shi-0') }}</div>
          <div class="down-time" :key="key">{{getDownTime(detailData.StartTime, detailData.EndTime)}}</div>
        </div>
      </div>

      <div class="info-btn">
        <Button class="gray" v-if="isLogin">
          {{mineTime > 0 ? `${$t('nin-yi-can-yu')}${mineTime}${$t('ci')}` : $t('nin-hai-wei-can-yu')}}
        </Button>
        <Button class="gray" v-else @click="toPath('login')">
          <div>
            <span class="blue mr-5">{{ $t('deng-lu') }}</span>
            {{ $t('yi-cha-kan-nin-de-duo-bao-hao-ma') }} </div>
        </Button>
      </div>
    </div>

    <div class="space-line"></div>

    <div class="menu-list">
      <div class="menu flex-center-between" @click="toContent">
        <div>{{ $t('tu-wen-xiang-qing') }}</div>
        <div class="icon flex-center">
          {{ $t('jian-yi-zai-wifi-xia-cha-kan') }} <Icon name="arrow"></Icon>
        </div>
      </div>
      <div class="menu flex-center-between" @click="toLog">
        <div>{{ $t('wang-qi-jie-xiao') }}</div>
        <div class="icon flex-center">
          <Icon name="arrow"></Icon>
        </div>
      </div>
    </div>

    <div class="space-line"></div>

    <div class="join-log">
      <div class="flex-center-between top">
        <div class="label">{{ $t('suo-you-can-yu-ji-lu') }}</div>
        <div class="gray">{{logStartTime ? formatTimeByDayjs(logStartTime) + `开始` : ''}}</div>
      </div>
      <div class="list-box">
        <div class="list" v-if="logList.length > 0">
          <div class="time">{{formatTimeByDayjs(logStartTime)}}</div>
          <div class="log">

            <div class="info-box" v-for="(item, i) in logList" :key="i">
              <div class="name"><span>{{item.User.Email || item.User.Phone}}</span> {{ $t('can-yu-bian-hao') }}<span>({{ item.Number }})</span></div>
              <!-- <div class="address">(泰国IP:45.******.123)</div> -->
              <div class="total">
                {{ $t('can-yu-le') }} <span class="red">1</span>
                {{ $t('ren-ci-2') }} <span class="gray">{{formatTimeByDayjs(item.CreatedAt)}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="space-line"></div>

    <div class="page-bottom flex-center-between" v-if="pageStatus != 3">
      <div>{{ $t('xin-yi-qi-zheng-zai-huo-re-jin-hang-zhong') }}</div>
      <div>
        <Button @click="toNext" :disabled="btnDisabled">{{ $t('li-ji-qian-wang') }}</Button>
      </div>
    </div>
    <div class="page-bottom flex-center" v-else>
      <Button class="big flex-1" @click="submit">{{ $t('li-ji-can-yu') }}</Button>
    </div>
  </div>
</template>
<script>
import topBar from '@/components/topBar'
import { Swipe, SwipeItem, Button, Icon } from 'vant'
import { getToken, getUserId } from '@/utils/auth'
import {getStartTime, formatTimeByDayjs, getDownTime, syncTime} from '@/utils/common'
import { participationList, participationAdd, periodsList, nextPeriod } from '@/api/index'
import { addCar } from '@/api/shop'
export default {
  components: {
    topBar,
    Swipe,
    SwipeItem,
    Button,
    Icon
  },
  data() {
    return {
      key: 0,
      timer: '',
      newestUrl: require('../../assets/imgs/newest-goods.jpg'),
      clockUrl: require('../../assets/imgs/icon-clock.png'),
      headerUrl: require('../../assets/imgs/icon-user2.jpg'),
      carUrl: require('../../assets/imgs/icon-car2.png'),
      bannerList: [],
      isLogin: false,
      pageStatus: 3,
      detailData: {},
      logList: [],
      logStartTime: '',
      winId: '',
      mineTime: 0,
      btnDisabled: false
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  watch: {
    $route(val) {
      this.initDetail()
    }
  },
  mounted() {

    if (getToken()) {
      this.isLogin = true
    }
    this.initDetail()
  },
  methods: {
    formatTimeByDayjs,
    getStartTime,
    getDownTime,
    changeRoute() {
      let imgs = this.detailData.Product.Images ? JSON.parse(this.detailData.Product.Images) : ''
      if (imgs && imgs.length > 0 && imgs instanceof Array) {
        this.bannerList = imgs
      } else {
        this.bannerList = [this.detailData.Product.MainImage]
      }
      let now = new Date().getTime()
      let end = syncTime(this.detailData.EndTime).getTime()
      let start = syncTime(this.detailData.StartTime).getTime()
      if (now >= end) {
        this.pageStatus = 1
      } else if (now < end && now >= start) {
        this.pageStatus = 3
      } else {
        this.pageStatus = 2
      
      }
      if (this.pageStatus != 1) {
        this.timer = setInterval(() => {
          this.key += 1
        }, 1000);
      }
      if (this.isLogin) {
        this.initLog()
        this.initUserId()
      }
    },
    back() {
      this.$router.go(-1)
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    initDetail() {
      let id = this.$route.query.id
      let form = new FormData()
      form.append('ID', id)
      periodsList(form).then(res => {
        this.detailData = res.data.Items[0]
        this.getDownTime(this.detailData.StartTime, this.detailData.EndTime)
        this.changeRoute()
      })
    },
    initUserId() {
      let form = new FormData()
      form.append('pageSize', 1000)
      form.append('PeriodID', this.detailData.ID)
      form.append('PeriodIdProductId', this.detailData.ProductID)
      participationList(form).then(res => {
        let list = res.data.Items
        if (list.length > 0) {
          let winList = list.filter(v => {
            return v.IsWin == 1
          })
          if (winList.length > 0) {
            this.winId = winList[0].User.ID
          }
        }
      })
    },
    initLog() {
      let form = new FormData()
      form.append('pageSize', 1000)
      form.append('PeriodID', this.detailData.ID)
      form.append('PeriodIdProductId', this.detailData.ProductID)
      form.append('UId', getUserId())
      participationList(form).then(res => {
        this.logList = res.data.Items.reverse()
        let mineLog = this.logList.filter(v => {
          return getUserId() == v.User.ID
        })
        this.mineTime = mineLog.length
      
      })
    },
    submit() {
      let form = new FormData()
      form.append('PeriodID', this.detailData.ID)
      participationAdd(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('can-yu-cheng-gong'))
          this.initLog()
          this.initDetail()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    toContent() {
      sessionStorage.setItem('prizeContent', this.detailData.Product.ProductTranslation[0].Detail)
      this.toPath('prizeContent')
    },
    addToCar() {
      let form = new FormData()
      form.append('PeriodsId', this.detailData.ID)
      addCar(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('tian-jia-gou-wu-che-cheng-gong'))
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    toLog() {
      this.$router.push({
        name: 'prizeLog',
        query: {
          id: this.detailData.ProductID
        }
      })
    },
    toNext() {
      let form = new FormData()
      form.append('ProductID', this.detailData.ProductID)
      nextPeriod(form).then(res => {
        let data = res.data
        if (this.detailData.ID !== data.ID) {
          sessionStorage.setItem('prizeDetailData', JSON.stringify(data))
          this.$router.replace({
            name: 'prizeDetail',
            query: {
              id: data.ID,
            }
          })
        } else {
          this.$toast(this.$t('yi-shi-zui-xin-qi-jing-qing-qi-dai'))
          this.btnDisabled = true
        }
      })
    }
  }
}
</script>