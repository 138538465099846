<template>
  <div class="mine-page">
    <div class="top">
      <div class="flex-center-between">
        <div class="flex-center flex-1">
          <div class="header" @click="toPath('header')" :class="`header-${avatar}`">
          </div>
          <div class="flex-1 w-1">
            <div class="name">{{userName}}</div>
            <div class="tag">{{`VIP${userLevel}`}}</div>
          </div>
        </div>
        <div class="flex-center right-icon" >
          <!-- <img :src="msgUrl" alt="" @click="toPath('message')"> -->
          <i class="iconfont icon-gear" @click="toPath('setting')"></i>
        </div>
      </div>
    </div>


    <div class="menu-box">
      <div class="list flex-start flex-wrap wrap">

        <div class="menu flex-column-center" v-for="(item, i) in menuList3" :key="i" @click="toPath(item.path)">
          <img :src="item.img" alt="">
          <span>{{item.name}}</span>
        </div>
      </div>
    </div>



    <Popup v-model="showPopup">
      <div class="share-img">
        <div class="img">
          <img :src="shareImg" alt="">
        </div>
        <div class="download-btn flex-column-center">
          <img :src="downloadUrl" alt="">
          <span>{{ $t('chang-an-hai-bao-bao-cun-tu-pian') }}</span>
        </div>
      </div>
    </Popup>

    <div class=" share-preview" ref="sharePreview">
      <div class="img">
        <img :src="shareUrl" alt="">
      </div>
      <div class="qrcode" ref="qrcode" id="qrcode">

      </div>
    </div>
  </div>
</template>
<script>
import { Divider, List, Popup  } from 'vant'
import { shopGoods } from '@/api/index'
import { userInfo } from '@/api/user'
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'
import {setUser, setUserId} from "@/utils/auth";
export default {
  components: {
    Divider,
    List,
    Popup
  },
  data() {
    return {
      showPopup: false,
      userName: '',
      shareImg: '',
      headerUrl: require('../../assets/imgs/header/header1.png'),
      msgUrl: require('../../assets/imgs/icon-message.png'),
      couponUrl: require('../../assets/imgs/icon-wallet2.png'),
      integralUrl: require('../../assets/imgs/img-scoreGIft.png'),
      downloadUrl: require('../../assets/imgs/download.png'),
      shareUrl: require('../../assets/imgs/share.png'),
      shopUrl: require('../../assets/imgs/icon-shop.png'),
      openUrl: require('../../assets/imgs/icon-openShop.png'),
      isInit: false,
      loading: false,
      finished: false,
      page: {
        page: 1,
        limit: 100,
      },
      totalPage: 1,
      goodsList: [],
      shareLink: '',
      isInit: false,
      userLevel: 1
    }
  },
  computed: {
    isSeller() {
      return this.$store.state.isSeller
    },
    isInitUserInfo() {
      return this.$store.state.isInitUserInfo
    },
    promoteUrl() {
      return this.$store.state.promoteUrl
    },
    avatar() {
      return this.$store.state.avatar
    },
    menuList3() {
      return [
        {
          img: require('../../assets/imgs/icon-wallet.png'),
          name: this.$t('wo-de-qian-bao'),
          path: 'wallet'
        },
        {
          img: require('../../assets/imgs/icon-address.png'),
          name: this.$t('shou-huo-di-zhi'),
          path: 'address'
        },
        {
          img: require('../../assets/imgs/icon-kefu.png'),
          name: this.$t('ke-fu'),
          path: 'onlineService'
        },
        {
          img: require('../../assets/imgs/icon-about.png'),
          name: this.$t('guan-yu-wo-men'),
          path: 'about'
        },
        {
          img: require('../../assets/imgs/icon-about.png'),
          name: this.$t('she-zhi'),
          path: 'setting'
        },
        {
          img: require('../../assets/imgs/icon-about.png'),
          name: this.$t('wo-yao-tui-guang'),
          path: 'share'
        },
      ]
    }
  },
  mounted() {
    this.initGoods()
    this.init()
  },
  methods: {
    init() {
      userInfo().then(res => {
        let data = res.data
        this.$store.state.userInfo = res.data
        this.userName = data.Phone || data.Email
        setUser(this.userName)

        if (data.Avatar) {
          this.$store.state.avatar = data.Avatar
        }

        this.userLevel = data.Level ? data.Level.Level : 1

        let isSeller = data.IsBusiness == 1
        this.isInit = true
        this.$store.state.isSeller = isSeller
        this.$store.state.isInitUserInfo = true
        this.$store.state.shareCode = data.InvitationCode

        this.shareLink = this.promoteUrl + data.InvitationCode
        this.initShare()
      })
    },
    toPath(path, query) {
      if (path == 'kefu') {
        this.$router.push({
          name: 'chat',
          query: {
            id: '12312123123'
          }
        })
      } else {
        if (path) {
          this.$router.push({
            name: path,
            query: query || {}
          })
        }
      }
    },
    onLoad() {
      this.page.page += 1
      this.initGoods(true)
    },
    initGoods(isAdd) {
      this.loading = true

      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', 10)
      form.append('Guess', 2)
      shopGoods(form).then(res => {

        if (isAdd) {
          this.goodsList = this.goodsList.concat(res.data.Items)
        } else {
          this.goodsList = res.data.Items
        }
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.page.page) {
          this.finished = true
        }
        this.isInit = true
        this.loading = false
      }).catch(err => {
        this.isInit = true
        this.loading = false
      })
    },
    toGoodsDetail(data) {
      this.$router.push({
        name: 'goodsDetail',
        query: {
          id: data.ID,
          shopID: data.ShopID
        }
      })
    },
    toShare() {
      // this.shareImg = this.shareUrl

      // 生成图片
      html2canvas(this.$refs.sharePreview).then(canvas => {
        let img = canvas.toDataURL("image/png")
        this.shareImg = img
        this.showPopup = true
      })

    },
    initShare() {
      this.$nextTick(() => {
        document.getElementById('qrcode').innerHTML = ''
        let qrcode = new QRCode(this.$refs.qrcode, {
          text: this.shareLink,
          width: 130,
          height: 130,
          correctLevel: QRCode.CorrectLevel.H
        })
      })
    },
  }
}
</script>