var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-page"},[_c('div',{staticClass:"top flex-center-start"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.logoUrl,"alt":""}})]),_c('div',[_c('div',{staticClass:"name"},[_vm._v("Flubit")]),_c('div',{staticClass:"desc"},[_vm._v("App size：3M")]),_c('div',{staticClass:"download-btn"},[_c('Button',[_vm._v("Free installation")])],1),_c('div',{staticClass:"flex-center tag"},[_c('Icon',{attrs:{"name":"question","size":"22","color":"#0477f9"}}),_vm._v(" Safety Certificate ")],1)])]),_c('div',{staticClass:"score"},[_c('div',{staticClass:"flex-center-between"},[_c('div',{staticClass:"flex-center"},[_vm._v(" 4.9 "),_vm._l((5),function(i){return _c('Icon',{key:i,attrs:{"name":"star","size":"15","color":"#8E8F92"}})})],2),_c('div',[_vm._v("18 +")])]),_vm._m(0)]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-center-between desc"},[_c('div',[_vm._v("9,999 Ratings")]),_c('div',[_vm._v("Age")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc-box"},[_c('div',{staticClass:"desc-line"},[_vm._v("1、After downloading the Android phone, click Install")]),_c('div',{staticClass:"desc-line"},[_vm._v("2、Click on the Iphone phone to return to the desktop after installation. If the download is complete, click on Settings >> Universal >> description file >> Flubit >>Install")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro"},[_c('div',{staticClass:"title"},[_vm._v("Introduction")]),_c('div',{staticClass:"desc"},[_vm._v("Extremely fast download")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro"},[_c('div',{staticClass:"title"},[_vm._v("Ratings and Reviews")]),_c('div',{staticClass:"flex-center-between"},[_c('div',{staticClass:"flex-column-center"},[_c('div',{staticClass:"count big"},[_vm._v("4.9")]),_c('div',{staticClass:"gray mt-10"},[_vm._v("out of 5")])]),_c('div',[_c('div',{staticClass:"star-line flex-center"},[_c('div',{staticClass:"star-bg"}),_c('div',{staticClass:"line-bg"},[_c('div',{staticClass:"line w-95"})])]),_c('div',{staticClass:"star-line flex-center"},[_c('div',{staticClass:"star-bg"},[_c('div',{staticClass:"white-bg w-1"})]),_c('div',{staticClass:"line-bg"},[_c('div',{staticClass:"line w-5"})])]),_c('div',{staticClass:"star-line flex-center"},[_c('div',{staticClass:"star-bg"},[_c('div',{staticClass:"white-bg w-2"})]),_c('div',{staticClass:"line-bg"})]),_c('div',{staticClass:"star-line flex-center"},[_c('div',{staticClass:"star-bg"},[_c('div',{staticClass:"white-bg w-3"})]),_c('div',{staticClass:"line-bg"})]),_c('div',{staticClass:"star-line flex-center"},[_c('div',{staticClass:"star-bg"},[_c('div',{staticClass:"white-bg w-4"})]),_c('div',{staticClass:"line-bg"})]),_c('div',{staticClass:"gray"},[_vm._v("9,999 Ratings")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro"},[_c('div',{staticClass:"title"},[_vm._v("New function")]),_c('div',{staticClass:"desc"},[_vm._v("Version V1.0#")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro"},[_c('div',{staticClass:"title"},[_vm._v("Information")]),_c('div',{staticClass:"info-line flex-center-between"},[_c('div',{staticClass:"label"},[_vm._v("Sellers")]),_c('div')]),_c('div',{staticClass:"info-line flex-center-between"},[_c('div',{staticClass:"label"},[_vm._v("Size")]),_c('div',{staticClass:"value"},[_vm._v("3M")])]),_c('div',{staticClass:"info-line flex-center-between"},[_c('div',{staticClass:"label"},[_vm._v("Language")]),_c('div',{staticClass:"value"},[_vm._v("Multi-language")])]),_c('div',{staticClass:"info-line flex-center-between"},[_c('div',{staticClass:"label"},[_vm._v("Age rating")]),_c('div',{staticClass:"value"},[_vm._v("18 years old and above")])]),_c('div',{staticClass:"info-line flex-center-between"},[_c('div',{staticClass:"label"},[_vm._v("Price")]),_c('div',{staticClass:"value"},[_vm._v("Free")])]),_c('div',{staticClass:"info-line flex-center-between"},[_c('div',{staticClass:"green"},[_vm._v("Privacy Policy")]),_c('div',{staticClass:"value"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-desc"},[_c('div',[_vm._v("Software Description：")]),_c('div',[_vm._v(" Flubit is a cross-border e-commerce platform jointly released by Flubit Flubit Foundation Inc of the United States in conjunction with a number of local e-commerce platforms to sell products from RCEP countries to the EU.... ")])])
}]

export { render, staticRenderFns }