<template>
	<div class="home-container fixed">

    <div class="top-search flex-center">
      <!-- <div class="iconfont icon-search icon" @click="toPath('search')"></div> -->
      <div class="flex-center title">
        {{ $t('shou-ye') }} </div>
      <!-- <div class="icon"></div> -->
    </div>

    <div class="banner">
      <Swipe :autoplay="3000" indicator-color="#d93a55">
        <SwipeItem v-for="(item, i) in bannerList" :key="i">
          <div class="img">
            <img :src="item.Img" alt="">
          </div>
        </SwipeItem>
      </Swipe>
    </div>


    <div class="menu-list flex-start">
      <div class="menu flex-column-center" v-for="(item, i) in menuList" :key="i" @click="toPath(item.path, item.query)">
        <div class="icon">
          <img :src="item.url" alt="">
        </div>
        <div class="label">{{item.name}}</div>
      </div>
    </div>

    <div class="notice-bar">
      <NoticeBar
        left-icon="volume-o"
        :scrollable="false"
        background="none"
      >
        <Swipe class="notice-swipe" vertical  :autoplay="3000"  :show-indicators="false">
          <SwipeItem v-for="(item, i) in noticeList" :key="i">{{$t('gong-xi')}} {{item.UId}} {{$t('huo-de')}} {{ getName(item) }}</SwipeItem>
        </Swipe>
      </NoticeBar>
    </div>

    <div class="sub-title">{{ $t('common.tab2') }}</div>
    <div class="newest-goods flex" >
      <div class="goods flex-column-center" v-for="(item, i) in newestGoodsList" :key="i"  @click="toGoodsDetail(item)">
        <img :src="`${item.Product.MainImage}`" alt="">
        <div class="label">
          <span>{{ $t('dao-ji-shi') }}</span>
          <span class="red" :key="`${item.ID}-${key}`">{{getDownTime(item.StartTime, item.EndTime)}}</span>
        </div>
      </div>
    </div>



    <div class="bottom-goods-list">
      <div class="filter-line flex">
        <div class="option" :class="filterIndex == 1 ? 'active' : ''" @click="changeOption(1)">{{ $t('ren-qi') }}</div>
        <div class="option" :class="filterIndex == 2 ? 'active' : ''" @click="changeOption(2)">{{ $t('tui-jian') }}</div>
        <div class="option" :class="filterIndex == 3 ? 'active' : ''" @click="changeOption(3)">{{ $t('zui-xin') }}</div>
        <div class="option" :class="filterIndex == 4 ? 'active' : ''" @click="changeOption(4)">{{ $t('jin-du') }}</div>
        <div class="option more" :class="filterIndex == 5 ? 'active' : ''" @click="changeOption(5)">
          <div class="flex">
            <div class="option-inner"> {{ $t('zong-xu-ren-shu') }} </div>
            <div class="flex-column-center icon">
              <Icon name="arrow-up" size="10" :color="filterIndex == 5 && moreType == 1 ? '#d93a55' : '#6e7d8b'"></Icon>
              <Icon name="arrow-down" size="10" :color="filterIndex == 5 && moreType == 2 ? '#d93a55' : '#6e7d8b'"></Icon>
            </div>
          </div>
        </div>
      </div>

       <List
				v-if="bottomGoodsList.length > 0"
				v-model="loading"
				:finished="finished"
				:finished-text="isInit && bottomGoodsList.length == 0 ? '' : $t('common.noMore')"
				@load="onLoad">
        <div class="list flex-wrap">
          <div class="goods" v-for="(item, i) in bottomGoodsList" :key="i"  @click="toGoodsDetail(item)">
            <div class="img flex-center">
              <img :src="`${item.Product.MainImage}`" alt="">
            </div>
            <div class="label">
               {{getName(item)}}
            </div>
            <div class="down-time">{{getDownTime(item.StartTime, item.EndTime)}}</div>
            <div class="info">
              <div class="percent-box">
                <div class="percent" :style="{width: `${item.JoinCount / item.LimitCount * 100}%`}"></div>
              </div>
              <div class="flex-center-between count">
                <div>{{item.JoinCount}}</div>
                <div>{{item.LimitCount - item.JoinCount}}</div>
              </div>
              <div class="flex-center-between info-label">
                <div>{{ $t('yi-can-yu-ren-shu') }}</div>
                <div>{{ $t('sheng-yu-ren-shu') }}</div>
              </div>
            </div>
            <div class="price">${{item.Price }}</div>
          </div>
        </div>
			</List>
    </div>

    <Popup v-model="showPopup" class="gift-popup">
      <div class="gift-modal flex-column-center">
        <img :src="giftUrl" alt="">
        <div class="content">{{ $t('gong-xi-ni-yi-jing-zhong-jiang') }}</div>
        <Button class="link" @click="toViewPrize">{{ $t('cha-kan-xiang-qing') }}</Button>
      </div>
    </Popup>
	</div>
</template>
<script>
import { Button, Swipe, SwipeItem, Popup, Icon, NoticeBar, List  } from 'vant'
import { periodsList, getBannerList, winningTips, winningRead, getNoticeList} from '@/api/index'
import { addCar } from '@/api/shop'
import { Dialog } from 'vant';
import { getDownTime } from '@/utils/common';
import moment from 'moment'
import {getToken} from "@/utils/auth";
export default {
  components: {
    Button,
    Swipe,
    SwipeItem,
    Popup,
    Icon,
    NoticeBar,
    Dialog,
    List
  },
  data() {
    return {
      key: 0,
      timer: '',
      isLogin: false,
      showMsg: false,
      avatar: '',
      tabIndex: 1,
      userName: '',
      userMoney: '0.00',
      carUrl: require('../../assets/imgs/icon-car.png'),
      chatUrl: require('../../assets/imgs/icon-chat.png'),
      langUrl: require('../../assets/imgs/icon-lang.png'),
      couponUrl: require('../../assets/imgs/icon-coupon.svg'),
      moneyUrl: require('../../assets/imgs/icon-money.svg'),
      giftUrl: require('../../assets/imgs/raffle-gift.png'),
      newestUrl: require('../../assets/imgs/newest-goods.jpg'),
      shoppingUrl: require('../../assets/imgs/icon-shopping.png'),
      bannerList: [],
      noticeList: [],
      moreType: 1,
      noticeMsg: '',
      goodsList: [],
      bottomGoodsList: [],
      showPopup: false,
      isInit: false,
      loading: false,
      finished: false,
      Content: "",
      page: {
        page: 1,
        limit: 100,
      },
      totalPage: 1,
      newestGoodsList: [],
      filterIndex: 3,
      winData: {}
    }
  },
  computed: {
    screenScale() {
      return this.$store.state.screenScale || 1
    },
    menuList() {
      return  [
        {
          url:  require('../../assets/imgs/home-menu1.png'),
          name: this.$t('fen-lei-0'),
          path: 'category'
        },

        {
          url:  require('../../assets/imgs/home-menu3.png'),
          name: this.$t('jie-xiao'),
          path: 'category'
        },
        {
          url:  require('../../assets/imgs/icon-raffle.png'),
          // url:  require('../../assets/imgs/home-menu4.png'),
          name: this.$t('raffle.label4'),
          path: 'raffle'
        },
        {
          url:  require('../../assets/imgs/icon-kefu.png'),
          name: this.$t('ke-fu'),
          path: 'onlineService',
        },
      ]
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  mounted() {
    this.init()
    this.initGoods()
    this.initWin()
    this.initNotice()
  },
  methods: {
    getDownTime,
    toPath(path, query) {
      if (path) {
        this.$router.push({
          name: path,
          query: query || {}
        })
      }
    },
    getName(item){
      try {
        return item.Periods.Product.ProductTranslation[0].Name
      }catch (e) {
        return ''
      }
    },
    changeOption(i) {
      if (i == 5 && this.filterIndex == 5) {
        this.moreType = this.moreType == 1 ? 2 : 1
      }
      this.filterIndex = i
      this.initGoods()
    },
    checkPage() {
      if (this.okNum == 4) {
        this.$store.state.showLoading = false
      }
    },
    initNotice() {
      getNoticeList().then(res => {
        this.noticeList = res.data
        console.log(111, this.noticeList)
      })
    },
    initWin() {
      if(!getToken()) {
        return
      }
      winningTips().then(res => {
        let data = res.data
        if (data.IsWin == 1 && data.IsRead == 2) {
          this.showPopup = true
          this.winData = data
        }
      })
    },
    init() {
      // 轮播图
      getBannerList().then(res => {
        this.bannerList = res.data.Items
        this.okNum += 1
        this.checkPage()
      })

      // 最新揭晓
      let form = new FormData()
      form.append('OrderBy', 'at_once')
      periodsList(form).then(res => {
        this.newestGoodsList = res.data.Items.slice(0, 3)
        this.timer = setInterval(() => {
          this.key += 1
        }, 1000);
      })
    },
    resetTime(time) {
      return moment(time).format('MM/DD HH:mm')
    },
    cancel() {
      this.$store.state.showWelcome = false
    },
    getBoxWidth(len) {
      return `width: ${len * 135 * this.screenScale}px;`
    },
    onLoad() {
      this.page.page += 1
      this.initGoods(true)
    },
    initGoods(isAdd) {
      this.loading = true

      let form = new FormData()
      let order = ''
      switch(this.filterIndex) {
        case 1: order = 'popularity'; break;
        case 2: order = 'recommended'; break;
        case 3: order = 'latest'; break;
        case 4: order = 'schedule'; break;
        case 5: order = this.moreType == 1 ? 'total_asc' : 'total_desc'; break;
      }
      form.append('OrderBy', order)
      form.append('GroupBy', 'product_id')
      periodsList(form).then(res => {

        if (isAdd) {
          this.bottomGoodsList = this.bottomGoodsList.concat(res.data.Items)
        } else {
          this.bottomGoodsList = res.data.Items
        }
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.page.page) {
          this.finished = true
        }
        this.isInit = true
        this.loading = false
      }).catch(err => {
        this.isInit = true
        this.loading = false
      })
    },
    toGoodsDetail(data) {
      sessionStorage.setItem('prizeDetailData', JSON.stringify(data))
      this.$router.push({
        name: 'prizeDetail',
        query: {
          id: data.ID,
        }
      })
    },
    addToCar(data) {
      let form = new FormData()
      form.append('PeriodsId', data.ID)
      addCar(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('tian-jia-gou-wu-che-cheng-gong'))
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    toViewPrize() {
      let form = new FormData()
      form.append('ID', this.winData.ID)
      winningRead(form).then(res => {
        this.showPopup = false
        this.$router.push({
          name: 'prizeDetail',
          query: {
            id: this.winData.ID,
          }
        })
      })
    }
  }
}
</script>