import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'
import store from '../store'

import homePage from '@/views/home/index'
import indexPage from '@/views/index/index'
import newestPage from '@/views/newest/newest'
import categoryPage from '@/views/index/category'
import findPage from '@/views/find/find'
import carPage from '@/views/shopping/car'
import minePage from '@/views/mine/mine'

import prizeDetailPage from '@/views/newest/prizeDetail'
import prizeLogPage from '@/views/newest/prizeLog'
import prizeContentPage from '@/views/newest/prizeContent'
import rafflePage from '@/views/index/raffle'

import signPage from '@/views/index/sign'
import signLogPage from '@/views/index/signLog'
import newGoodsPage from '@/views/shopping/newGoods'
import goodsDetailPage from '@/views/shopping/goodsDetail'
import searchPage from '@/views/shopping/search'
import searchResultPage from '@/views/shopping/searchResult'
import orderPage from '@/views/shopping/order'
import orderListPage from '@/views/shopping/orderList'
import orderDetailPage from '@/views/shopping/orderDetail'
import refundPage from '@/views/shopping/refund'
import refundDetailPage from '@/views/shopping/refundDetail'
import myEvalutePage from '@/views/shopping/myEvalute'
import goodsEvalutePage from '@/views/shopping/goodsEvalute'
import goodsEvaluteListPage from '@/views/shopping/goodsEvaluteList'
import feedbackPage from '@/views/shopping/feedback'

import shopPage from '@/views/shop/shop'
import openShopPage from '@/views/shop/openShop'

import couponPage from '@/views/mine/coupon'
import couponCenterPage from '@/views/mine/couponCenter'
import integralPage from '@/views/mine/integral'
import walletPage from '@/views/mine/wallet'
import rechargePage from '@/views/mine/recharge'
import withdrawPage from '@/views/mine/withdraw'
import debtPage from '@/views/mine/debt'
import rechargeLogPage from '@/views/mine/rechargeLog'
import withdrawLogPage from '@/views/mine/withdrawLog'
import sharePage from '@/views/mine/share'
import headerPage from '@/views/mine/header'

import settingPage from '@/views/setting/setting'
import editPasswordPage from '@/views/setting/editPassword'
import editSafePasswordPage from '@/views/setting/editSafePassword'
import addressPage from '@/views/setting/address'
import addAddressPage from '@/views/setting/addAddress'
import bankPage from '@/views/setting/bank'
import addBankPage from '@/views/setting/addBank'
import langPage from '@/views/setting/lang'
import helpPage from '@/views/setting/help'
import aboutPage from '@/views/setting/about'
import editInfoPage from '@/views/setting/editInfo'
import messagePage from '@/views/setting/message'
import chatPage from '@/views/setting/chat'
import noticeDetailPage from '@/views/setting/noticeDetail'
import downloadPage from '@/views/setting/download'

import myTeamPage from '@/views/team/myTeam'
import commissionPage from '@/views/team/commission'
import withdrawInfoPage from '@/views/team/withdrawInfo'

import loginPage from '@/views/login/login'
import registerPage from '@/views/login/register'
import resetPasswordPage from '@/views/login/resetPassword'
import onlineService from '@/views/mine/onlineService'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      index: 3
    },
    component: loginPage
  },
  {
    path: '/onlineService',
    name: 'onlineService',
    meta: {
      index: 3
    },
    component: onlineService
  },
  {
    path: '/to-login',
    name: 'to-login',
    meta: {
      index: 3
    },
    component: () => import('@/views/login/tologin'),
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      index: 4
    },
    component: registerPage
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    meta: {
      index: 4
    },
    component: resetPasswordPage
  },
  {
    path: '/home',
    name: 'home',
    component: homePage,
    children: [
      // {
      //   path: '/index',
      //   name: 'index',
      //   meta: {
      //     index: 1
      //   },
      //   component: indexPage,
      // },
      {
        path: '/newest',
        name: 'newest',
        meta: {
          index: 1
        },
        component: newestPage,
      },
      {
        path: '/find',
        name: 'find',
        meta: {
          index: 1
        },
        component: findPage,
      },

      {
        path: '/orderList',
        name: 'orderList',
        meta: {
          index: 1
        },
        component: orderListPage,
      },
      {
        path: '/mine',
        name: 'mine',
        meta: {
          index: 1
        },
        component: minePage,
      },


    ]
  },
  {
    path: '/sign',
    name: 'sign',
    meta: {
      index: 2
    },
    component: signPage,
  },
  {
    path: '/category',
    name: 'category',
    meta: {
      index: 2
    },
    component: categoryPage,
  },
  {
    path: '/car',
    name: 'car',
    meta: {
      index: 3
    },
    component: carPage,
  },
  {
    path: '/signLog',
    name: 'signLog',
    meta: {
      index: 3
    },
    component: signLogPage,
  },
  {
    path: '/coupon',
    name: 'coupon',
    meta: {
      index: 2
    },
    component: couponPage,
  },
  {
    path: '/couponCenter',
    name: 'couponCenter',
    meta: {
      index: 3
    },
    component: couponCenterPage,
  },
  {
    path: '/newGoods',
    name: 'newGoods',
    meta: {
      index: 2
    },
    component: newGoodsPage,
  },
  {
    path: '/integral',
    name: 'integral',
    meta: {
      index: 2
    },
    component: integralPage,
  },
  {
    path: '/wallet',
    name: 'wallet',
    meta: {
      index: 2
    },
    component: walletPage,
  },
  {
    path: '/recharge',
    name: 'recharge',
    meta: {
      index: 3
    },
    component: rechargePage,
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    meta: {
      index: 3
    },
    component: withdrawPage,
  },
  {
    path: '/debt',
    name: 'debt',
    meta: {
      index: 4
    },
    component: debtPage,
  },
  {
    path: '/rechargeLog',
    name: 'rechargeLog',
    meta: {
      index: 4
    },
    component: rechargeLogPage,
  },
  {
    path: '/withdrawLog',
    name: 'withdrawLog',
    meta: {
      index: 4
    },
    component: withdrawLogPage,
  },
  {
    path: '/share',
    name: 'share',
    meta: {
      index: 4
    },
    component: sharePage,
  },
  {
    path: '/header',
    name: 'header',
    meta: {
      index: 4
    },
    component: headerPage,
  },
  {
    path: '/goodsDetail',
    name: 'goodsDetail',
    meta: {
      index: 3
    },
    component: goodsDetailPage,
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      index: 3
    },
    component: searchPage,
  },
  {
    path: '/searchResult',
    name: 'searchResult',
    meta: {
      index: 3
    },
    component: searchResultPage,
  },
  {
    path: '/order',
    name: 'order',
    meta: {
      index: 4
    },
    component: orderPage,
  },

  {
    path: '/refund',
    name: 'refund',
    meta: {
      index: 4
    },
    component: refundPage,
  },
  {
    path: '/refundDetail',
    name: 'refundDetail',
    meta: {
      index: 5
    },
    component: refundDetailPage,
  },
  {
    path: '/myEvalute',
    name: 'myEvalute',
    meta: {
      index: 3
    },
    component: myEvalutePage,
  },
  {
    path: '/goodsEvalute',
    name: 'goodsEvalute',
    meta: {
      index: 4
    },
    component: goodsEvalutePage,
  },
  {
    path: '/goodsEvaluteList',
    name: 'goodsEvaluteList',
    meta: {
      index: 5
    },
    component: goodsEvaluteListPage,
  },
  {
    path: '/feedback',
    name: 'feedback',
    meta: {
      index: 3
    },
    component: feedbackPage,
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    meta: {
      index: 5
    },
    component: orderDetailPage,
  },
  {
    path: '/shop',
    name: 'shop',
    meta: {
      index: 3
    },
    component: shopPage,
  },
  {
    path: '/openShop',
    name: 'openShop',
    meta: {
      index: 3
    },
    component: openShopPage,
  },
  {
    path: '/setting',
    name: 'setting',
    meta: {
      index: 2
    },
    component: settingPage,
  },
  {
    path: '/editPassword',
    name: 'editPassword',
    meta: {
      index: 3
    },
    component: editPasswordPage,
  },
  {
    path: '/editSafePassword',
    name: 'editSafePassword',
    meta: {
      index: 3
    },
    component: editSafePasswordPage,
  },
  {
    path: '/address',
    name: 'address',
    meta: {
      index: 2
    },
    component: addressPage,
  },
  {
    path: '/addAddress',
    name: 'addAddress',
    meta: {
      index: 3
    },
    component: addAddressPage,
  },
  {
    path: '/bank',
    name: 'bank',
    meta: {
      index: 2
    },
    component: bankPage,
  },
  {
    path: '/addBank',
    name: 'addBank',
    meta: {
      index: 3
    },
    component: addBankPage,
  },
  {
    path: '/lang',
    name: 'lang',
    meta: {
      index: 3
    },
    component: langPage,
  },
  {
    path: '/help',
    name: 'help',
    meta: {
      index: 3
    },
    component: helpPage,
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      index: 3
    },
    component: aboutPage,
  },
  {
    path: '/editInfo',
    name: 'editInfo',
    meta: {
      index: 4
    },
    component: editInfoPage,
  },
  {
    path: '/message',
    name: 'message',
    meta: {
      index: 4
    },
    component: messagePage,
  },
  {
    path: '/index',
    name: 'index',
    meta: {
      index: 5
    },
    component: chatPage,
  },
  {
    path: '/noticeDetail',
    name: 'noticeDetail',
    meta: {
      index: 5
    },
    component: noticeDetailPage,
  },
  {
    path: '/download',
    name: 'download',
    meta: {
      index: 5
    },
    component: downloadPage,
  },
  {
    path: '/myTeam',
    name: 'myTeam',
    meta: {
      index: 3
    },
    component: myTeamPage,
  },
  {
    path: '/commission',
    name: 'commission',
    meta: {
      index: 3
    },
    component: commissionPage,
  },
  {
    path: '/withdrawInfo',
    name: 'withdrawInfo',
    meta: {
      index: 3
    },
    component: withdrawInfoPage,
  },
  {
    path: '/prizeDetail',
    name: 'prizeDetail',
    meta: {
      index: 3
    },
    component: prizeDetailPage,
  },
  {
    path: '/prizeLog',
    name: 'prizeLog',
    meta: {
      index: 3
    },
    component: prizeLogPage,
  },
  {
    path: '/prizeContent',
    name: 'prizeContent',
    meta: {
      index: 3
    },
    component: prizeContentPage,
  },
  {
    path: '/raffle',
    name: 'raffle',
    meta: {
      index: 3
    },
    component: rafflePage,
  },





  {
    path: '/seller',
    name: 'seller',
    component: () => import('@/views/backend/layout'),
    children: [
      {
        path: '/seller/dashboard',
        name: 'sellerDashboard',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/dashboard'),
      },
      {
        path: '/seller/product/list',
        name: 'productList',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/product/productList'),
      },
      {
        path: '/seller/product/edit',
        name: 'editProduct',
        meta: {
          index: 4
        },
        component: () => import('@/views/backend/product/editProduct'),
      },
      {
        path: '/seller/product/reviews',
        name: 'productReviews',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/product/reviewList'),
      },
      {
        path: '/seller/product/storehouse',
        name: 'productStorehouse',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/product/storehouse'),
      },
      {
        path: '/seller/orders',
        name: 'sellerOrders',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/order/orderList'),
      },
      {
        path: '/seller/orderDetail',
        name: 'selleOrderDetail',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/order/orderDetail'),
      },
      {
        path: '/seller/package',
        name: 'sellerPackage',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/shop/package'),
      },
      {
        path: '/seller/packagePayList',
        name: 'packagePayList',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/shop/packagePayList'),
      },
      {
        path: '/seller/spreadPackage',
        name: 'spreadPackage',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/shop/spreadPackage'),
      },
      {
        path: '/seller/spreadPackagePayList',
        name: 'spreadPackagePayList',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/shop/spreadPackagePayList'),
      },
      {
        path: '/seller/affiliate',
        name: 'sellerAffiliate',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/setting/affiliate'),
      },
      {
        path: '/seller/financialCenter',
        name: 'financialCenter',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/setting/financialCenter'),
      },
      {
        path: '/seller/conversations',
        name: 'sellerConversations',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/setting/conversations'),
      },
      {
        path: '/seller/sellerConversationsDetail',
        name: 'sellerConversationsDetail',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/setting/sellerConversationsDetail'),
      },
      {
        path: '/seller/conversationDetail',
        name: 'conversationDetail',
        meta: {
          index: 3,
          parent: 'sellerConversations'
        },
        component: () => import('@/views/backend/setting/conversationDetail'),
      },
      {
        path: '/seller/shopSetting',
        name: 'shopSetting',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/shop/setting'),
      },
      {
        path: '/seller/coupon',
        name: 'sellerCoupon',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/shop/coupon'),
      },
      {
        path: '/seller/addCoupon',
        name: 'sellerAddCoupon',
        meta: {
          index: 3,
          parent: 'sellerCoupon'
        },
        component: () => import('@/views/backend/shop/addCoupon'),
      },
      {
        path: '/seller/refund',
        name: 'sellerRefund',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/setting/refund'),
      },
      {
        path: '/seller/payments',
        name: 'sellerPayments',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/setting/payments'),
      },
      {
        path: '/seller/commission',
        name: 'sellerCommission',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/setting/commission'),
      },
      {
        path: '/seller/creditScore',
        name: 'sellerCreditScore',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/setting/creditScore'),
      },
      {
        path: '/seller/productQueries',
        name: 'productQueries',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/product/productQueries'),
      },
      {
        path: '/seller/productQueryDetail',
        name: 'productQueryDetail',
        meta: {
          index: 3,
          parent: 'productQueries'
        },
        component: () => import('@/views/backend/product/productQueryDetail'),
      },
      {
        path: '/seller/ticketDetail',
        name: 'sellerTicketDetail',
        meta: {
          index: 3,
          parent: 'sellerTicket'
        },
        component: () => import('@/views/backend/order/ticketDetail'),
      },
      {
        path: '/seller/ticket',
        name: 'sellerTicket',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/order/ticket'),
      },
      {
        path: '/seller/fileList',
        name: 'sellerFileList',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/file/fileList'),
      },
      {
        path: '/seller/password',
        name: 'sellerPassword',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/setting/password'),
      },
      {
        path: '/seller/userInfo',
        name: 'sellerUserInfo',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/setting/userInfo'),
      },
      {
        path: '/seller/verify',
        name: 'sellerVerify',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/setting/verify'),
      },
    ]
  },
  {
    path: '*',
    redirect: 'index'
  }
]

const router = new VueRouter({
  routes
})

const whiteArr = ['register', 'login','to-login', 'lang', 'index', 'task', 'setting', 'about', 'help','onlineService'] //白名单
const whitePage = ['coupon', 'couponCenter', 'index', 'login', 'register', 'resetPassword', 'search', 'lang', 'debt', 'rechargeLog', 'withdrawLog',
                   'help', 'about', 'noticeDetail', 'download', 'newest', 'prizeDetail', 'prizeContent'] //页面背景白色
const loadingArr = [ // loading页面

]
const statusBarPage = ['index']
router.beforeEach((to, from, next) => {
  // 背景色
  if (whitePage.indexOf(to.name) > -1) {
    document.body.style.background = '#fff'
  } else {
    document.body.style.background = '#f4f4f4'
  }

  let screenWidth =  document.getElementById('app').offsetWidth
  if (screenWidth > 540) {
    screenWidth = 540
  }
  let size = screenWidth / 10

  if (to.path.indexOf('/seller/') > -1) { // 商家后台
    document.body.className = 'sellerBox'
    document.getElementsByTagName("html")[0].style.fontSize = '16px'
  } else {
    document.body.className = ''
    document.getElementsByTagName("html")[0].style.fontSize = size + 'px'
  }

  let token = getToken()
  if (token) {
    next()
  } else {
    // 白名单
    if (whiteArr.indexOf(to.name) > -1) {
      next()
    } else {
      // next('login')
      next()
    }
  }
})

router.afterEach((to, from) => {
  window.scrollTo({top: 0})
})

export default router
