<template>
  <div class="category-page">
    <top-bar :title="$t('fen-lei-0')" :isWhite="true" @back="back"></top-bar>

    <div class="search-box">
      <Search  v-model="form.keyword" :placeholder="$t('sou-suo')" @input="doSearch" @search="doSearch"/>
    </div>

    <div class="category-box ">
      <div class="all flex-center-start" @click="toSearch({})">
        <div class="icon flex-center">
          <img :src="moreUrl" alt="">
        </div>
        <span>{{ $t('quan-bu-shang-pin') }}</span>
      </div>
      <div class="title">{{ $t('fen-lei-liu-lan') }}</div>
      <div class="classify flex-center-start" v-for="(item, i) in categoryList" :key="i" @click="toSearch(item)">
        <img :src="item.Image || categoryUrl" alt="">
        <span>
          {{item.ProductCategoryTranslations[0].Name}}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import topBar from '@/components/topBar'
import {  Swipe, SwipeItem, Search } from 'vant'
import { getCategoryList } from '@/api/index'
export default {
  components: {
    Swipe,
    SwipeItem,
    Search,
    topBar
  },
  data() {
    return {
      form: {
        keyword: ''
      },
      tabIndex: 0,
      categoryUrl: require('../../assets/imgs/icon-classify.png'),
      moreUrl: require('../../assets/imgs/icon-more.png'),
      logoUrl: require('../../assets/imgs/logo.png'),
      allCategoryList: [],
      categoryList: [],
      categoryListBak: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    init() {
      let form = new FormData()
      form.append('pageSize', 5000)
      getCategoryList(form).then(res => {
        this.categoryList = res.data.Items
        this.categoryListBak = res.data.Items
      })
    },
    resetCategoryData(res) {
      // 推荐分类
      let showCategoryList = res.data.Items.filter(v => {
        return v.IsHot == 1 && v.IsShow == 1
      })
      let firstCategoryList  = this.resetHotCategory(showCategoryList)

      // 其他分类
      let categoryList = res.data.Items.filter(v => {
        return v.IsShow == 1
      })
      let allCategoryList = this.resetCategory(categoryList, 0)
      allCategoryList = this.clearEmptyChild(allCategoryList) // 合并没有3级的分类
      //左侧一级分类
      this.classifyList = [{
        name: this.$t('tui-jian-fen-lei')
      }].concat(allCategoryList.map(v => {
        return {
          name:v.title
        }
      }))

        // 备份所有数据
      this.allCategoryList = [{
        children: firstCategoryList
      }].concat(allCategoryList)

      this.categoryList = this.allCategoryList[this.tabIndex].children || []
    },
    clearEmptyChild(list) {
      let newList = list.map((v, i) => {
        let otherList = []
        if (v.children && v.children.length > 0) {
          v.children.forEach(val => {
            if (!val.children || val.children.length == 0) {
              otherList.push(val)
            }
          })
          v.children = v.children.filter(val => {
            return val.children && val.children.length > 0
          }).concat({
            title: '',
            children: otherList
          })
        }
        return v
      })
      return newList
    },
    resetHotCategory(list) { // 推荐分类
      let newList = [{
        title: '',
        children: []
      }]
      list.forEach(v => {
        let data = v.ProductCategoryTranslations[0]
        newList[0].children.push({
          ...v,
          title:  data ? data.Name : '',
        })
      })
      return newList
    },
    resetCategory(list, parentId) {
      let treeData = []
      list.forEach(v => {
        if (v.ParentID == parentId) {
          let otherList = list.filter(val => {
            return val.ParentID != parentId
          })
          let children = this.resetCategory(otherList, v.ID)
          if (children.length > 0) {
            v.children = children
          }
          let data = v.ProductCategoryTranslations[0]
          treeData.push({
            ...v,
            title: data ? data.Name : ''
          })
        }
      })
      return treeData
    },
    changeClassify( i) {
      this.tabIndex = i
      this.categoryList = this.allCategoryList[i].children || []
    },
    toSearch(data) {
      this.$router.push({
        name: 'searchResult',
        query: {
          categoryId: data.ID || '',
          title: data.ProductCategoryTranslations ? data.ProductCategoryTranslations[0].Name : ''
        }
      })
    },
    doSearch() {
      if (this.form.keyword) {
        this.categoryList = this.categoryListBak.filter(v => {
          return v.ProductCategoryTranslations.indexOf(this.form.keyword) > -1
        })
      } else {
        this.categoryList = [].concat(this.categoryListBak)
      }
    }
  }
}
</script>