import fetch from "../utils/fetch";

// 全局配置
export function configList(data) {
  return fetch({
    url: '/api/user/setting-list',
    method: 'post',
    data: data
  });
}
// 用户信息
export function userInfo(data) {
  return fetch({
    url: '/api/user/user-info',
    method: 'post',
    data: data
  });
}

// getKefuList 获取客服列表
export function getKefuList(data) {
  return fetch({
    url: '/api/user/customer-list',
    method: 'post',
    data: data
  });
}

// 收益详情
export function commissionInfo(data) {
  return fetch({
    url: '/api/mobile/home-commission',
    method: 'post',
    data: data
  });
}
// 团队收益
export function teamReport(data) {
  return fetch({
    url: '/api/mobile/team-report',
    method: 'post',
    data: data
  });
}
// 退出登录
export function logout(data) {
  return fetch({
    url: '/api/mobile/user/logout',
    method: 'delete',
  });
}
// 上传附件
export function uploadFile(data) {
  return fetch({
    url: '/api/user/upload',
    method: 'post',
    data,
  });
}
// usdt列表
export function getUsdtList (data) {
  return fetch({
    url: '/api/mobile/usdt/list',
    method: 'get',
    data
  })
}
// 添加USDT
export function addUsdt (data) {
  return fetch({
    url: '/api/mobile/usdt/bind',
    method: 'post',
    data
  })
}
// 提现
export function doWithdraw (data) {
  return fetch({
    url: '/api/user/withdraw-add',
    method: 'post',
    data
  })
}
// 提现记录
export function getWithdrawLog (data) {
  return fetch({
    url: '/api/user/withdraw-lists',
    method: 'post',
    data
  })
}
// 充值记录
export function rechargeLog (data) {
  return fetch({
    url: '/api/user/pay-record-lists',
    method: 'post',
    data
  })
}
//新增充值记录
export function addRecharge (data) {
  return fetch({
    url: '/api/user/pay-record-add',
    method: 'post',
    data
  })
}
// 充值地址信息
export function getRechargeInfo () {
  return fetch({
    url: '/api/user/payment-management-lists',
    method: 'post',
    data: {}
  })
}
// 货币列表
export function getCurrentList(data) {
  return fetch({
    url: '/api/user/monetary-function-list',
    method: 'post',
    data
  });
}
// 我的钱包
export function wallet(data) {
  return fetch({
    url: '/api/user/user-account-list',
    method: 'post',
    data
  });
}
// 银行卡列表
export function bankList(data) {
  return fetch({
    url: '/api/user/bank-list',
    method: 'post',
    data
  });
}
// 银行卡新增
export function addBank(data) {
  return fetch({
    url: '/api/user/bank-add',
    method: 'post',
    data
  });
}
// 银行卡删除
export function removeBank(data) {
  return fetch({
    url: '/api/user/bank-delete',
    method: 'post',
    data
  });
}
// 交易类型
export function payTypeList(data) {
  return fetch({
    url: '/api/user/transaction-details-type',
    method: 'post',
    data
  });
}
// 交易明细
export function payDetailList(data) {
  return fetch({
    url: '/api/user/transaction-details-list',
    method: 'post',
    data
  });
}
// 我的团队
export function myTeam(data) {
  return fetch({
    url: '/api/user/my-team',
    method: 'post',
    data
  });
}
// 修改头像
export function editAvatar(data) {
  return fetch({
    url: '/api/user/update-avatar',
    method: 'post',
    data
  });
}