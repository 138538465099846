var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-detail-page refund"},[_c('top-bar',{attrs:{"title":"退款详情","isWhite":true},on:{"back":_vm.back}}),_vm._m(0),_vm._m(1),_c('div',{staticClass:"pay-info small"},[_c('div',{staticClass:"title"},[_vm._v("退款信息")]),_c('div',{staticClass:"flex-center-between goods"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":_vm.goodsUrl,"alt":""}})]),_vm._m(2)]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top flex-center-between"},[_c('div',{staticClass:"status"},[_vm._v(" 退款已完成 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"refund-result"},[_c('div',{staticClass:"label"},[_vm._v("退款方式：我要退款（无需退货）")]),_c('div',{staticClass:"desc"},[_vm._v("退款已完成")]),_c('div',{staticClass:"desc"},[_vm._v("完成时间：2024-01-05 00:00:02")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"name"},[_vm._v("[ LUV IS TRUE ] DE CHERRY TEE - BASIC FIT 100% aut")]),_c('div',{staticClass:"price"},[_vm._v("299.99 x1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('div',{staticClass:"label"},[_vm._v("退款原因")]),_c('div',{staticClass:"value"},[_vm._v("PayPal")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('div',{staticClass:"label"},[_vm._v("退款说明")]),_c('div',{staticClass:"value"},[_vm._v("999.99")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('div',{staticClass:"label"},[_vm._v("退款金额")]),_c('div',{staticClass:"value"},[_vm._v("中通")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('div',{staticClass:"label"},[_vm._v("申请件数")]),_c('div',{staticClass:"value"},[_vm._v("0")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('div',{staticClass:"label"},[_vm._v("申请时间")]),_c('div',{staticClass:"value"},[_vm._v("-10.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('div',{staticClass:"label"},[_vm._v("退款编号")]),_c('div',{staticClass:"value"},[_vm._v("1298310238019283")])])
}]

export { render, staticRenderFns }