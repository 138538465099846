<template>
  <div class="prize-log-page">
    <top-bar :title="$t('wang-qi-jie-xiao-0')" :isWhite="true"  @back="back"></top-bar>

    <div class="list" v-for="(item, i) in logList" :key="i">
      <div class="title">{{ $t('qi-hao-1') }}{{item.PeriodId}}（{{$t('jie-xiao-shi-jian')}}{{item.UpdatedAt}}）</div>
      <div class="flex-start detail">
        <div class="header">
          <img :src="headerUrl" alt="">
        </div>
        <div>
          <div class="info-line">
            {{$t('yong-hu-id')}}{{item.User.ID}}{{$t('wei-yi-bu-bian-biao-shi')}}
          </div>
          <div class="info-line">
            <span class="label">{{ $t('ben-qi-can-yu') }}</span>
            <span class="">
              <span class="red">{{item.Periods.JoinCount}}</span>{{ $t('ren-ci-2') }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="empty empty-box" v-if="logList.length == 0 && isInit">
      <img :src="emptyUrl" alt="">
      <span>{{$t('common.empty')}}</span>
    </div>
  </div>
</template>
<script>
import topBar from '@/components/topBar'
import { Swipe, SwipeItem, Button, Icon } from 'vant' 
import { participationList } from '@/api/index'
export default {
  components: {
    topBar,
    Swipe,
    SwipeItem,
    Button,
    Icon
  },
  data() {
    return {
      newestUrl: require('../../assets/imgs/newest-goods.jpg'),
      clockUrl: require('../../assets/imgs/icon-clock.png'),
      headerUrl: require('../../assets/imgs/icon-user2.jpg'),
      emptyUrl: require('../../assets/imgs/empty2.png'),
      bannerList: [
        require('../../assets/imgs/newest-goods.jpg'),
        require('../../assets/imgs/newest-goods2.jpg'),
      ],
      isLogin: false,
      pageStatus: 3,
      productId: '',
      logList: [],
      isInit: false
    }
  },
  mounted() {
    let id = this.$route.query.id
    this.productId = id
    this.init()
  },
  methods: {
    init() {
      let form = new FormData()
      form.append('pageSize', 1000)
      form.append('ProductID', this.productId)
      participationList(form).then(res => {
        this.isInit = true
        this.logList = res.data.Items
      })
    },
    back() {
      this.$router.go(-1)
    }
  }
}
</script>