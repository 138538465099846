<template>
  <div class="car-page">
    <div class="top">{{ $t('gou-wu-che-0') }}</div>

    <div class="car-box" v-if="!showEmpty">
      <div class="title flex-center-between">
        <div>{{ $t('gong-shoppinglistlength-jian-bao-bei', [shoppingList.length]) }}</div>
        <div @click="changeManage">{{isManage ? $t('wan-cheng') : $t('guan-li')}}</div>
      </div>

      <div class="shopping-list">
        <div class="shop" >
          <div class="goods-list"> 
            <div class="goods flex-center" v-for="(goods, index) in shoppingList" :key="index">
              <div class="icon" v-show="isManage">
                <Checkbox checked-color="#fa3534"  icon-size="16" v-model="goods.checked" @click.stop="chooseGoods(index)"></Checkbox>
              </div>
              <div class="flex-1 flex-center ml-10" @click="toGoodsDetail(goods)">
                <div class="img">
                  <img :src="goods.Periods.Product.MainImage" alt="">
                </div>
                <div  class="info flex-1">
                  <div class="goods-name">{{goods.Periods.Product.ProductTranslation && goods.Periods.Product.ProductTranslation[0] ? goods.Periods.Product.ProductTranslation[0].Name : ''}}</div>
                  <div class="flex-center-between price-line">
                    <div class="price">{{goods.Periods.Price }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom flex-center-between" v-show="isManage">
      <div class="flex-center">
        <Checkbox checked-color="#fa3534" icon-size="16" v-model="checkAll" @click="chooseAllGoods"></Checkbox>
        <span class="ml-10">{{ $t('quan-xuan-0') }}</span>
      </div>
      <div class="flex-center" v-if="!isManage">
        {{ $t('gong-ji') }} <span class="price">{{totalPrice.toFixed(2)}}</span>
      </div>
      <div>
        <Button @click="submit">{{isManage ? $t('shan-chu') : $t('li-ji-jie-suan')}}</Button>
      </div>
    </div>

    <div class="empty flex-column-center" v-if="showEmpty">
      <img :src="emptyUrl" alt="">
      <span>{{ $t('nin-de-gou-wu-che-kong-kong-ru-ye') }}</span>
      <Button @click="toPath('index')">{{ $t('dao-chu-guang-guang') }}</Button>
    </div>
  </div>
</template>
<script>
import { Button, Checkbox, Icon } from 'vant'
import { carList, removeCar } from '@/api/shop'
export default {
  components: {
    Button,
    Checkbox,
    Icon
  },
  data() {
    return {
      emptyUrl: require('../../assets/imgs/empty2.png'),
      showEmpty: false,
      isManage: false,
      checkAll: false,
      totalPrice: 0,
      shoppingList: [ ],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      carList().then(res => {
        let list = res.data.Items
        this.shoppingList = list 

      })
    },
    minusNumber(i, index) {
      let Number = this.shoppingList[i].goodsList[index].Number || 1
      if (Number > 1) {
        Number -= 1
      }
      this.$set(this.shoppingList[i].goodsList[index], 'Number', Number)
    },
    plusNumber(i, index) {
      let Number = this.shoppingList[i].goodsList[index].Number || 1
      Number += 1
      this.$set(this.shoppingList[i].goodsList[index], 'Number', Number)
    },
    changeManage() {
      this.isManage = !this.isManage
    },
    chooseGoods(index) {
      this.resetCheckAll()
    },
    chooseAllGoods() {
      this.shoppingList.forEach((v, i) => {
        this.$set(this.shoppingList[i], 'checked', this.checkAll)
      })
    },
    resetCheckAll() {
      let len = this.shoppingList.filter(v => {
        return v.checked
      }).length
      this.checkAll = len == this.shoppingList.length
      // this.getTotalPrice()
    },
    getTotalPrice() {
      let total = 0
      let list = []
      this.shoppingList.forEach(v => {
        v.goodsList.forEach(val => {
          if (val.checked) {
            list.push(val)
          }
        })
      })
      list.forEach(v => {
        let price = v.optionPrice || v.BusinessProduct.SalePrice
        total += price * v.Number
      })
      this.totalPrice = total
    },
    submit() {
      let list = []
      this.shoppingList.forEach(val => {
        if (val.checked) {
          list.push(val)
        }
      })
      let ids = list.map(v => {
        return v.ID
      })
      if (this.isManage) { // 管理
        let i = 0
        ids.forEach(v => {
          let form = new FormData()
          form.append('ID', v)
          removeCar(form).then(res => {
            if (res.code == 0) {
              i += 1
              if (i == ids.length) {
                this.$toast.success(this.$t('shan-chu-cheng-gong-1'))
                this.init()
              }
            } else {
              this.$toast.fail(res.msg)
            }
          }).catch(e => {
            i += 1
            if (i == ids.length) {
              this.$toast.success(this.$t('xi-tong-chu-cuo'))
              this.init()
            }
          })
        })
      } else { // 购买
        let orderData = this.shoppingList.map(v => {
          return {
            Shop: v.ShopID,
            goodsList: v.goodsList.map(val => {
              return {
                ...val.BusinessProduct,
                BusinessProductSpecificationsRelationID: val.BusinessProductSpecificationsRelationID,
                count: val.Number,
                optionName: val.optionName,
                optionPrice: val.optionPrice
              }
            })

          }
        })


        sessionStorage.setItem('orderData', JSON.stringify(orderData))
        this.$router.push({
          name: 'order'
        })
      }
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    toGoodsDetail(data) {
      sessionStorage.setItem('prizeDetailData', JSON.stringify(data.Periods))
      this.$router.push({
        name: 'prizeDetail',
        query: {
          id: data.Periods.ID,
        }
      })
    },
  }
}
</script>