<template>
  <div class="prize-content-page">
    <top-bar :title="$t('tu-wen-xiang-qing')" :isWhite="true"  @back="back"></top-bar>

    <div class="detail" v-html="content">
      
    </div>
  </div>
</template>
<script>
import topBar from '@/components/topBar'
import { Swipe, SwipeItem, Button, Icon } from 'vant' 
export default {
  components: {
    topBar,
    Swipe,
    SwipeItem,
    Button,
    Icon
  },
  data() {
    return {
      content: ''
    }
  },
  mounted() {
    let conetnt = sessionStorage.getItem('prizeContent')
    if (conetnt) {
      this.content = conetnt
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    }
  }
}
</script>