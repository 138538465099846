<template>
  <div class="search-page">
    <top-bar :title="title" :isWhite="true" @back="back"></top-bar>

    <div class="tabs flex" >
      <div class="tab flex-center" :class="tabIndex == 1 ? 'active' : ''" @click="changeTab(1)">{{ $t('ren-qi') }}</div>
      <div class="tab  flex-center" :class="tabIndex == 2 ? 'active' : ''" @click="changeTab(2)">{{ $t('tui-jian') }}</div>
      <div class="tab  flex-center" :class="tabIndex == 3 ? 'active' : ''" @click="changeTab(3)">{{ $t('zui-xin') }}</div>
      <div class="tab  flex-center" :class="tabIndex == 4 ? 'active' : ''" @click="changeTab(4)">{{ $t('jin-du') }}</div>
      <div class="tab more flex-center" :class="tabIndex == 5 ? 'active' : ''" @click="changeTab(5)">
        <div class="flex">
          <div class="option-inner"> {{ $t('zong-xu-ren-shu') }} </div>
          <div class="flex-column-center icon">
            <Icon name="arrow-up" size="10" :color="tabIndex == 5 && moreType == 1 ? '#d93a55' : '#6e7d8b'"></Icon>
            <Icon name="arrow-down" size="10" :color="tabIndex == 5 && moreType == 2 ? '#d93a55' : '#6e7d8b'"></Icon>
          </div>
        </div>
      </div>
    </div>

    <div class="goods-list">
      <div class="list flex-wrap" >
        <List
          v-if="goodsList.length > 0"
          v-model="loading"
          :finished="finished"
          :finished-text="isInit && goodsList.length == 0 ? '' : $t('common.noMore')"
          @load="onLoad">
          <div class="list flex-wrap">
            <div class="goods" v-for="(item, i) in goodsList" :key="i"  @click="toGoodsDetail(item)">
            <div class="img flex-center">
              <img :src="`${item.Product.MainImage}`" alt="">
            </div>
            <div class="label">
               {{item.Product.ProductTranslation[0].Name}}   
            </div>
            <div class="info">
              <div class="percent-box">
                <div class="percent" :style="{width: `${item.JoinCount / item.LimitCount * 100}%`}"></div>
              </div>
              <div class="flex-center-between count">
                <div>{{item.JoinCount}}</div>
                <div>{{item.LimitCount - item.JoinCount}}</div>
              </div>
              <div class="flex-center-between info-label">
                <div>{{ $t('yi-can-yu-ren-shu') }}</div>
                <div>{{ $t('sheng-yu-ren-shu') }}</div>
              </div>
            </div>
            <div class="price">${{item.Price }}</div>
          </div>
          </div>
        </List>
      </div>
      
    </div>
  </div>
</template>
<script>
import topBar from '@/components/topBar'
import { Icon, Popover , Search, Popup, Button, List } from 'vant'
import { periodsList } from '@/api/index'
import { addCar } from '@/api/shop'
export default {
  components: {
    Icon,
    Popover,
    Search,
    Popup,
    Button,
    List,
    topBar
  },
  data() {
    return {
      shoppingUrl: require('../../assets/imgs/icon-shopping.png'),
      tabIndex: 1,
      moreType: '',
      showPopover: false,
      showPopup: false,
      pageType: 1,
      categoryId: '',
      priceType: '',
      title: '',
      form: {
        searchType: '1',
        keyword: '',
        typeName: this.$t('shang-pin'),
        sort: ''
      },
      typeList: [
        {
          text: this.$t('shang-pin'),
          value: '1'
        },
        {
          text: this.$t('dian-pu'),
          value: '2'
        },
      ],
      isInit: false,
      loading: false,
      finished: false,
      page: {
        page: 1,
        limit: 10,
      },
      totalPage: 1,
      goodsList: [],
      shopData: {
        goodsList: []
      },
      priceMin: ''
    }
  },
  mounted() {
    this.categoryId = this.$route.query.categoryId || ''
    this.priceMin = this.$route.query.min
    this.title = this.$route.query.title || this.$t('quan-bu-shang-pin')
    this.initGoods()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeTab(i) {
      if (i == 5 && this.tabIndex == 5) {
        this.moreType = this.moreType == 1 ? 2 : 1
      }
      this.tabIndex = i
      this.initGoods()
    },
    onLoad() {
      this.page.page += 1
      this.initGoods(true)
    },
    initGoods(isAdd) {
      this.loading = true
      let form = new FormData()
      let order = ''
      switch(this.tabIndex) {
        case 1: order = 'popularity'; break;
        case 2: order = 'recommended'; break;
        case 3: order = 'latest'; break;
        case 4: order = 'schedule'; break;
        case 5: order = this.moreType == 1 ? 'total_asc' : 'total_desc'; break;
      }
      form.append('OrderBy', order)
      if (this.priceMin) {
        form.append('MinPrice', this.priceMin)
      } else {
        form.append('CategoryID', this.categoryId || '')
      }
      periodsList(form).then(res => {
        if (isAdd) {
          this.goodsList = this.goodsList.concat(res.data.Items)
        } else {
          this.goodsList = res.data.Items
        }
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.page.page) {
          this.finished = true
        }
        this.isInit = true
        this.loading = false
      }).catch(err => {
        this.isInit = true
        this.loading = false
      })
    },
    toGoodsDetail(data) {
      sessionStorage.setItem('prizeDetailData', JSON.stringify(data))
      this.$router.push({
        name: 'prizeDetail',
        query: {
          id: data.ID,
        }
      })
    },
    addToCar(data) {
      let form = new FormData()
      form.append('PeriodsId', data.ID)
      addCar(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('tian-jia-gou-wu-che-cheng-gong'))
        } else {
          this.$toast.fail(res.msg)
        }
      })
    }
  }
}
</script>