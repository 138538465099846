<template>
	<div class="raffle-page page-top">
		<top-bar :title="i18n.t('raffle.title')" class="border" :isWhite="true" @back="back" ></top-bar>

    <div class="top-img"></div>
    <div class="center-img"></div>

    <div class="raffle-info">
      <div class="raffle-box flex-wrap">
        <div class="box" v-for="(item, i) in raffleList" :key="i" :class="[i === activeIndex ? 'active' : '', i === 4 ? 'clear' : '']">
          <div class="box-inner flex-center" :class="i === 4 ? 'red' : ''">
            <div class="flex-column-center" v-if="i != 4 && item && !item.Img">
              <div class="count">{{item.Money}}</div>
              <div class="label bold flex-center">
                USDT
              </div>
            </div>
            <div v-if="i != 4 && item && item.Img">
              <img :src="`${`/upload/tmp/` + item.Img}`" class="big" alt="">
            </div>
            <div v-if="i == 4" class="flex-column-center desc">
              <img :src="giftUrl" alt="">
              <span class="label small"  :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'mini'">{{i18n.t('raffle.label8')}}</span>
            </div>
          </div>
        </div>
      </div>

<!--      <div class="sub-title">{{i18n.t('raffle.label3')}}</div>-->
      <div class="sub-title">{{ $t('mian-fei-chou-jiang-sheng-yu-lotterynum-ci', [LotteryNum]) }}</div>
      <div class="raffle-btn">
        <Button @click="submit">{{i18n.t('raffle.label4')}}</Button>
      </div>
    </div>

<!--    <div class="sub-title">免费抽奖剩余：0 次</div>-->
    <div class="sub-title">{{i18n.t('raffle.label5')}}</div>

    <div  class="result-box flex-center">
      <span v-if="resultObj.Img && showRaffle">
        <img :src="`/upload/tmp/${resultObj.Img}`" alt="">
      </span>
      <span v-else-if="!resultObj.Img && resultObj.Money && showRaffle">{{resultObj.Money}} USDT</span>
      <span v-else>{{i18n.t('raffle.label6')}}</span>
    </div>

    <div class="sub-title" v-show="false">{{i18n.t('raffle.label7')}}</div>

    <div class="rule-box">
      <div class="line" >
        <span class="label"></span>
        <div class="flex-1">{{ $t('shuo-ming') }}</div>
      </div>
      <div class="line" v-if="false">
        <span class="label">1.</span>
        <div class="flex-1">{{i18n.t('raffle.desc1')}}</div>
      </div>
      <div class="line" v-if="false">
        <span class="label">2.</span>
        <div class="flex-1">{{i18n.t('raffle.desc2')}}</div>
      </div>
      <div class="line" v-if="false">
        <span class="label"></span>
        <div class="flex-1">{{i18n.t('raffle.desc3')}} PrimaDonna Elite Espresso Machine（$2500）</div>
      </div>

     </div>
	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button, Icon} from 'vant'
import { getRaffleList, doRaffle } from '@/api/index'
import { userInfo } from '@/api/user'
export default {
	components: {
    Popup,
    Button,
    Icon,
		topBar
  },
  data() {
		return {
      giftUrl: require('../../assets/imgs/gift.png'),
      raffleList: [{}, {}, {}, {}, {count: 10000}, {}, {}, {}, {}],
      raffleIndex: 0, //
      raffleArr: [2, 5, 8, 7, 6, 3, 0, 1, 2, 5, 8, 7, 6, 3, 0, 1], // 转动顺序
      activeIndex: -1,
      timeList: [100, 200, 400, 800, 1200, 1600, 2500], // 不同阶段转速
      timeIndex: 0,
      allTime: 0,
      resultIndex: -1, // 开奖结果
      resultObj: {},
      LotteryNum: 0,
      showRaffle: false
		}
	},
  mounted() {
    this.init()
    this.getUserinfo()
  },
	methods: {
		init() {
      getRaffleList().then(res => {
        let list = res.data.Items
        if (list.length >= 8) {
          this.raffleList = list.slice(0, 8)
        } else {
          this.raffleList = list.concat(new Array(8 - list.length).join().split(','))
        }
        this.raffleList.splice(4, 0, {
          count: 10000
        })
        this.$store.state.showLoading = false
      })

		},
    getUserinfo() {
      userInfo().then(res => {
        this.user = res.data
        this.LotteryNum = res.data.LotteryNum
      })
    },
		back() {
			this.$router.go(-1)
		},
    toLog() {

    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    submit() {
      doRaffle().then(res => {
        this.getUserinfo()
        if (res.code == 0) {
          let data = res.data
          let id = data.PrizeDrawID
          let index = this.raffleList.findIndex(v => {
            return v.ID == id
          })
          this.resultIndex = index
          if (index > -1) {
            this.resultObj = this.raffleList[index]
          }
          this.start(true)
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    start(isStart) {
      let isEnd = false
      let isEmpty = false
      if (isStart) {
        this.timeIndex = 0
        this.allTime = 0
      }
      this.activeIndex = this.raffleArr[this.raffleIndex]
      let time = this.timeList[this.timeIndex]
      this.raffleIndex += 1
      if (this.raffleIndex > 8) {
        this.raffleIndex -= 8
      }
      this.allTime += time
      // 开奖动画，修改时间
      if (this.allTime > 5000  && this.allTime <= 8000) { // 第二阶段持续时间
        this.timeIndex = 1
      } else if (this.allTime > 8000 && this.allTime <= 13000) {
        this.timeIndex = 2
      } else if (this.allTime > 13000 && this.allTime <= 16000) {
        this.timeIndex = 3
      } else if (this.allTime > 16000 && this.allTime <= 21000) {
        this.timeIndex = 4
      } else if (this.allTime > 21000 && this.allTime <= 35000) {
        this.timeIndex = 5
      } else if (this.allTime > 34000) {
        this.timeIndex = 6
      }

      if (this.timeIndex >= 4 && this.resultIndex == this.activeIndex) { // 开奖
        isEnd = true
        this.showRaffle = true
      }
      if (this.timeIndex > 5) {
        isEnd = true
        isEmpty = true // 未中奖
        this.activeIndex = -1
      }
      console.log("this.showRaffle", this.showRaffle, this.resultObj.Img)
      if (!isEnd) {
        setTimeout(() => {
          this.start()
        }, time)
      } else {
        if (isEmpty) {
          this.$toast(this.i18n.t('raffle.fail'))
        } else {
          this.$toast(this.i18n.t('raffle.success'))
        }
      }
    }
	}
}
</script>